import React from 'react'
import Button from '../MainComponents/Button'
import circle from '../../Images/mobile-app.webp'
import servicesData from '../AboutContent/data';
import { useParams } from 'react-router';

function Process() {
    const { slug } = useParams();
    const service = servicesData.find(service => service.slug == slug);
    console.log(slug )
    return (
        <>
            <div className="container-fluid p-0">
                <div className="process">
                    <div className="row m-0 justify-content-lg-start justify-content-end">
                        <div className="col-lg-6 col-12 p-0" data-aos="fade-right">
                            <div className="title">{service ? service.details[0].title : 'Title'}</div>
                            <div className="heading">Our Process & Approch</div>
                            <div className="para"> {service ? service.details[0].para : "Upon conducting a detailed analysis on the target audience and the brand values, targeted users based on specific keywords and interests of users on google. Upon analysis, implemented intent based marketing through google ads so as to increase visibility and generate quality leads, that would eventually yield a high conversion rate."}</div>
                            <div className="point-box">
                                <div className="point">{service ? service.details[0].point_1 : 'Marketing Strategy' }</div>
                                <div className="point">{service ? service.details[0].point_2 : 'Technology Process' }</div>
                            </div>
                            <Button />
                            <div className="d-lg-none d-inline">
                                <div className="circle">
                                    <img className='w-100 h-100' src={service ? service.details[0].img : circle} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 col-10 d-lg-flex d-none justify-content-lg-start justify-content-end p-5 circle-img-col">
                            <div data-aos="fade-left">
                                <div className="circle">
                                    <img className='w-100 h-100' src={service ? service.details[0].img : circle} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Process
