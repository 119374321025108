import marketing from '../../Images/Services Details/Digital-Marketing.png'
import web from '../../Images/Services Details/Web-Development.png'
import graphic from '../../Images/Services Details/Graphic-Designing.png'
import app from '../../Images/Services Details/5071700.png'
import it from '../../Images/Services Details/IT-Consultancy.png'
import devop from '../../Images/Services Details/DevOps.png'
import crms from '../../Images/Services Details/CRM.png'
import content from '../../Images/Services Details/Content-Management.png'
import transformation from '../../Images/Services Details/Digital-Transformation.png'
import ecommerce from '../../Images/Services Details/Ecommerce-Solution.png'
import img1 from '../../Images/project/Screenshot 2025-03-26 174859.png'
import img2 from '../../Images/project/pakiatan property.png'
import img3 from '../../Images/project/love my fashion.png'
import img4 from '../../Images/project/nask intenational.png'
import img5 from '../../Images/project/studio Hub.png'


const servicesData = [
    {
        page: "Digital Marketing",
        slug: "digital-marketing",
        details: [
            {
                page: "Digital Marketing",
                img: marketing,
                title: "Leading With Strategy",
                para: "NaskSoft helps businesses thrive by utilizing digital platforms to enhance brand visibility, engage target audiences, and drive conversions. Our strategic approach includes email marketing, social media management, web advertising, and multimedia messaging, ensuring impactful connections across all digital channels.",
                point_1:'Marketing Strategy',
                point_2:'Technology Process',
                processHeading: 'Comprehensive Digital Marketing Process',
                processCard: [
                    { id: 1 ,heading: 'Market Research & Strategy', para: 'Analyzing target audiences, competitors, and trends to create a data-driven marketing strategy.' },
                    { id: 2 ,heading: 'Brand Positioning & Messaging', para: 'Crafting a strong brand identity, compelling messaging, and unique value propositions.' },
                    { id: 3 ,heading: 'Content Creation & Optimization', para: 'Developing high-quality, SEO-friendly content for websites, blogs, social media, and ads.' },
                    { id: 4 ,heading: 'Multi-Channel Marketing Execution', para: 'Implementing campaigns across search engines, social media, email, and paid ads.' },
                    { id: 5 ,heading: 'Lead Generation & Engagement', para: 'Capturing and nurturing leads through targeted campaigns, personalized messaging, and automation.' },
                    { id: 6 ,heading: 'Performance Analysis & Optimization', para: 'Monitoring key metrics, refining strategies, and improving conversions for better ROI.' },
                ],
                techHeading: "Digital Marketing Technologies",
                techPara: 'Smart Strategies, Measurable Results, Business Growth',
                techCard1: "Google Analytics & Google Ads",
                card1Para1: 'Track performance, analyze user behavior, and run targeted ads',
                techCard2: "SEO & Keyword Research Tools",
                card2Para2: 'Optimize search rankings with tools like SEMrush, Ahrefs, and Moz.',
                techCard3: "Social Media Management Tools",
                card3Para3: 'Automate and manage campaigns using Hootsuite, Buffer, and Meta Business Suite.',
                SecondHeading: "Looking to enhance your online presence and drive better engagement?",
                secondPara: "We have the digital marketing expertise to boost your brand visibility and maximize results.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    // {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Web Development",
        slug: "web-development",
        details: [
            {
                page: "Web Development",
                img: web,
                title: "Assertive Web Strategies",
                para: "We specialize in delivering tailored IT solutions to drive business growth. Our dedicated development teams ensure specialized expertise, robust quality assurance, and cost efficiency. Whether you require mobile or web applications, cross-platform development, or full-stack services, we have the skills and experience to bring your vision to life.",
                point_1:'End-to-End Development',
                point_2:'Scalable Web Systems',
                processHeading: 'Web Development Process',
                processCard: [
                    { id: 1 ,heading: 'Planning & Strategy', para: 'Defining goals, audience, and technical requirements for a solid foundation.' },
                    { id: 2 ,heading: 'Design & Prototyping', para: 'Creating intuitive, visually appealing designs that enhance user experience.' },
                    { id: 3 ,heading: 'Responsive Development', para: 'Building mobile-friendly, fast-loading websites for seamless browsing.' },
                    { id: 4 ,heading: 'E-commerce Integration', para: 'Developing secure online stores with smooth checkout and inventory management.' },
                    { id: 5 ,heading: 'CMS Implementation', para: 'Setting up WordPress, Joomla, or other CMS for easy content updates.' },
                    { id: 6 ,heading: 'Web & Mobile App Development', para: 'Creating dynamic websites and mobile apps for enhanced accessibility.' },
                ],
                techHeading: "Technologies Used in Web Development",
                techPara: 'The Technologies Powering the Future of Web Development',
                techCard1: "Front-End Technologies",
                card1Para1: 'Using HTML, CSS, and JavaScript (React, Angular, Vue) for responsive and interactive interfaces.',
                techCard2: "Back-End Technologies",
                card2Para2: 'Developing with Node.js, Python, and PHP for secure and efficient server-side functionality.',
                techCard3: "Database Management",
                card3Para3: 'Storing and managing data with MySQL, PostgreSQL, and MongoDB for smooth performance.',
                SecondHeading: "Need a fast, dynamic website to boost your business presence?",
                secondPara: "Our web development team creates fast, responsive, and user-friendly solutions.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    // {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Graphic Design Services",
        slug: "graphic-design",
        details: [
            {
                page: "Graphic Design Services",
                img: graphic,
                title: "Visualise Innovative Visions",
                para: "NaskSoft delivers visually striking designs that enhance brand identity and create meaningful connections with your audience. Our expert team focuses on crafting compelling graphics that effectively communicate your message, ensuring a consistent and impactful presence across all platforms.",
                point_1:'Visual Communication',
                point_2:'Creative Branding',
                processHeading: 'Graphic Design Process',
                processCard: [
                    {id:1 , heading: 'Discovery & Planning', para: 'We analyze your goals, brand identity, and audience to define the project scope.' },
                    {id:2 , heading: 'Research & Concept Development', para: 'Our team explores trends and brainstorms ideas that align with your brand vision.' },
                    {id:3 , heading: 'Design & Prototyping', para: 'We create sketches and mock-ups for your review and feedback.' },
                    {id:4 , heading: 'Revisions & Refinement', para: 'Designs are refined based on feedback to ensure clarity and brand consistency.' },
                    {id:5 , heading: 'Finalization & Delivery', para: 'Approved designs are delivered in the required formats, and ready for use.' },
                    {id:6 , heading: 'Implementation', para: 'We assist in applying the final designs across platforms for seamless execution.' },
                ],
                techHeading: "Graphic Designing Technologies",
                techPara: 'Essential Graphic Design Tools for Modern Creatives',
                techCard1: "Adobe Creative Suite",
                card1Para1: 'Industry-leading tools like Photoshop, Illustrator, and InDesign for creating high-quality visuals',
                techCard2: "Figma & Sketch",
                card2Para2: 'Powerful UI/UX design platforms for web and app prototyping with seamless collaboration.',
                techCard3: "Canva & CorelDRAW",
                card3Para3: 'User-friendly design tools for creating quick graphics, branding materials, and marketing visuals.',
                SecondHeading: "Want to enhance your brand identity with stunning visuals?",
                secondPara: "Our graphic design experts craft eye-catching designs that communicate your message and leave a lasting impression.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    // {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Mobile App Development",
        slug: "mobile-app-development",
        details: [
            {
                page: "Mobile App Development",
                img: app,
                title: "Mobile-First Innovation",
                para: "At NaskSoft, we specialize in designing and developing intuitive mobile applications that deliver seamless user experiences. Our solutions are tailored to align with your business objectives, enhance user engagement, and optimize functionality. Whether for iOS, Android, or cross-platform development, we ensure high-performance, scalable, and user-friendly apps that meet the evolving demands of the digital landscape.",
                point_1:'Mobile Solutions',
                point_2:'App Development',
                processHeading: 'Mobile App Development Process',
                processCard: [
                    {id: 1 , heading: 'Discovery & Planning', para: 'Defining goals, target audience, and project scope.' },
                    {id: 2 , heading: 'Research & Strategy', para: 'Conducting in-depth market research to inform app features, design, and user needs.' },
                    {id: 3 , heading: 'Design & Prototyping', para: 'Creating wireframes and prototypes for a seamless user experience' },
                    {id: 4 , heading: 'Development & Testing', para: 'Build the app with the latest technologies and ensure quality through testing.' },
                    {id: 5 , heading: 'Deployment & Support', para: 'Launching the app and providing continuous updates and maintenance.' },
                    {id: 6 , heading: 'Optimization & Scaling', para: 'Ensuring the app is optimized for performance and prepared for future growth and scalability.' }
                ],
                techHeading: "Technologies We Use",
                techPara: 'Cutting-Edge Technologies We Leverage for Innovation',
                techCard1: "Front-End Frameworks",
                card1Para1: 'Utilizing React Native and Flutter to build responsive and high-performance user interfaces.',
                techCard2: "Back-End Technologies",
                card2Para2: 'Employing Node.js and Django to ensure robust server-side functionality and seamless data management.',
                techCard3: "UI/UX Design Tools",
                card3Para3: 'Leveraging Sketch and Figma to create intuitive and engaging user experiences.',
                SecondHeading: "Want a seamless, high-performance mobile app to enhance your business?",
                secondPara: "Our expertise ensures intuitive design, smooth functionality, and an exceptional user experience.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    // {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "IT Consultancy",
        slug: "it-consultancy",
        details: [
            {
                page: "IT Consultancy",
                img: it,
                title: "Smarter Tech Strategies",
                para: "NaskSoft helps businesses streamline their IT infrastructure with tailored strategies that enhance security, improve scalability, and drive efficiency. Our expert consultants work closely with you to align technology with your business goals, ensuring seamless operations and future-ready solutions.",
                point_1:'Technology Consulting',
                point_2:'IT Solutions',
                processHeading: 'IT Consultancy Process',
                processCard: [
                    { id :1 , heading: 'Initial Consultation', para: 'Understanding your business goals, IT infrastructure, and key challenges.' },
                    { id :2, heading: 'Assessment & Analysis', para: 'Evaluating systems to identify vulnerabilities, inefficiencies, and improvement areas.' },
                    { id :3 , heading: 'Strategic Planning', para: 'Developing a tailored IT strategy for security, scalability, and efficiency.' },
                    { id :4 , heading: 'Implementation Support', para: 'Assisting with technology integration and seamless execution of solutions.' },
                    { id :5 , heading: 'Monitoring & Optimization', para: 'Providing ongoing support to enhance system performance and adapt strategies.' },
                    { id: 6 , heading: 'Change Management', para: 'Ensuring smooth adoption of new technologies and minimizing disruption.' },
                ],
                techHeading: "Advanced IT Technologies",
                techPara: 'Technologies We Rely On for Exceptional Results',
                techCard1: "Cybersecurity Solutions",
                card1Para1: 'Implementing advanced security protocols and tools to protect your data and systems from threats.​',
                techCard2: "Cloud Computing Platforms",
                card2Para2: 'Leveraging services like AWS and Azure to provide scalable and flexible infrastructure solutions.​',
                techCard3: "Network Infrastructure ",
                card3Para3: 'Utilizing cutting-edge networking technologies to ensure robust and efficient connectivity.',
                SecondHeading: "Ready to optimize your IT systems for better security and scalability? ",
                secondPara: "Contact NaskSoft to discover how our IT consultancy services can transform your business.",
                projectCard:[
                    // {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "DevOps",
        slug: "devops",
        details: [
            {
                page: "DevOps",
                img: devop,
                title: "Unified DevOps Delivery",
                para: "We empower businesses with cutting-edge DevOps strategies that automate workflows, enhance collaboration, and accelerate software delivery. Our seamless integration of development and operations ensures faster deployments, greater scalability, and high-quality applications. With continuous integration, automated testing, and optimized deployment pipelines, we help you stay ahead in a fast-evolving digital landscape.",
                point_1:'DevOps Consulting',
                point_2:'Automation & Deployment',
                processHeading: 'Our DevOps Process',
                processCard: [
                    {id: 1, heading: 'Planning & Collaboration', para: 'Aligning development and operations teams to define project goals.' },
                    {id: 2, heading: 'Continuous Integration (CI)', para: 'Regularly merging code to detect issues early and maintain consistency.' },
                    {id: 3, heading: 'Continuous Testing', para: 'Automating tests to ensure software reliability and performance.' },
                    {id: 4, heading: 'Continuous Deployment (CD)', para: 'Automating code releases for faster and error-free deployments.' },
                    {id: 5, heading: 'Security Integration', para: 'Incorporating security measures throughout the development process to ensure secure code.' },
                    {id: 6, heading: 'Monitoring & Feedback', para: 'Tracking performance and user insights for continuous improvement.' },
                ],
                techHeading: "Cutting-Edge Technologies We Implement",
                techPara: 'Leading-Edge Technologies We Utilize to Transform Outcomes',
                techCard1: "CI/CD Tools ",
                card1Para1: 'Utilizing Jenkins and GitLab CI to automate integration and deployment pipelines.',
                techCard2: "Configuration Management",
                card2Para2: 'Implement configuration management tools to automate system setups to maintain consistency.',
                techCard3: "Containerization and Orchestration",
                card3Para3: 'Ensure smooth workload distribution, faster deployments, and improved resource utilization.',
                SecondHeading: "Poised to enhance your DevOps strategy?",
                secondPara: "Partner with NaskSoft to optimize your software development lifecycle and achieve faster, more efficient deployments.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    // {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "CRMs",
        slug: "crms",
        details: [
            {
                page: "CRMs",
                img: crms,
                title: "Customer-Centric Solutions",
                para: "Seamless interactions, smarter operations, and business growth start with NaskSoft’s CRM solutions. Our expertise in CRM strategy and technology implementation ensures a well-structured, high-performing system tailored to your business needs.",
                point_1:'CRM Services',
                point_2:'Customer Management',
                processHeading: 'Our CRM Process',
                processCard: [
                    {id: 1, heading: 'Developing CRM Strategy', para: 'We analyze customer segments, set goals, and define technology needs.' },
                    {id: 2, heading: 'Building CRM Project Foundations', para: 'We establish governance, assess risks, and set success factors.' },
                    {id: 3, heading: 'User Training & Adoption', para: 'Ensure your team effectively uses the CRM system, driving adoption and maximizing its value.' },
                    {id: 4, heading: 'Specifying Needs and Partner Selection', para: 'We refine processes, analyze gaps, and select the right CRM solution.' },
                    {id: 5, heading: 'Project Implementation', para: 'We customize, test, and deploy the CRM system seamlessly.' },
                    {id: 6, heading: 'Performance Evaluation', para: 'We assess outcomes, resolve issues, and optimize performance.' },
                ],
                techHeading: "Powering Our Solutions",
                techPara: 'Enabling Our Solutions through Advanced Technology',
                techCard1: "Front-End Frameworks",
                card1Para1: 'We build intuitive, responsive interfaces for seamless user experiences.',
                techCard2: "Back-End Technologies",
                card2Para2: 'Our robust systems ensure secure, scalable, and efficient performance.',
                techCard3: "UI/UX Design",
                card3Para3: 'We create user-friendly, visually engaging, and functional designs.',
                SecondHeading: "Seeking seamless data integration and deeper business insights?",
                secondPara: "Our CRM expertise ensures seamless integration and enhanced visibility for your operations.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    // {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Content Management",
        slug: "content-management",
        details: [
            {
                page: "Content Management",
                img: content, 
                title: "Simplifying Digital Content",
                para: "At NaskSoft, we provide end-to-end Content Management Services that streamline content creation and distribution, optimizing your digital presence and workflow efficiency. Our strategic approach and cutting-edge technologies ensure seamless, tailored solutions that meet your business needs.",
                point_1:'Content Solutions',
                point_2:'Digital Content Management',
                processHeading: 'Content Management Process',
                processCard: [
                    {id: 1, heading: 'Content Strategy Development', para: 'We define a strategy aligned with your brand, audience, and goals.' },
                    {id: 2, heading: 'Content Creation', para: 'Our team crafts and curates high-quality, engaging content.' },
                    {id: 3, heading: 'Content Organization', para: 'We structure, store, and secure digital assets for easy access' },
                    {id: 4, heading: 'Content Distribution', para: 'We publish content across multiple channels for maximum reach.' },
                    {id: 5, heading: 'Analysis and Optimization', para: 'We track performance, refine strategies, and optimize content.' },
                    {id : 6, heading: 'Content Maintenance', para: 'Ensure the content remains relevant, accurate, and aligned with evolving trends and audience needs.' },
                ],
                techHeading: "Cutting-Edge Content Technologies",
                techPara: 'Next-Gen Technologies Shaping the Content Creation',
                techCard1: "Content Management Systems",
                card1Para1: 'We leverage top CMS platforms for seamless content creation and management.',
                techCard2: "Digital Asset Management Tools",
                card2Para2: 'Our DAM tools securely store, organize, and retrieve digital assets.',
                techCard3: "Analytics and SEO Tools",
                card3Para3: 'We use advanced tools to track performance and optimize content reach.',
                SecondHeading:"Looking for efficient content management and streamlined workflows?",
                secondPara:"Our expertise ensures seamless content integration, optimized workflows, and enhanced visibility, empowering your business with structured, accessible, and well-managed information.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    // {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Digital Transformation",
        slug: "digital-transformation",
        details: [
            {
                page: "Digital Transformation",
                img: transformation, 
                title: "Transforming With Technology",
                para: "We help businesses unlock their full potential by leveraging cutting-edge technology to drive innovation, efficiency, and sustainable growth. Our Digital Transformation Services are designed to seamlessly transition your organization into the digital era, enabling you to stay competitive, agile, and responsive in an ever-evolving market landscape. ",
                point_1:'Digital Solutions',
                point_2:'Business Transformation',
                processHeading: 'Digital Transformation Process',
                processCard: [
                    {id:1 , heading: 'Strategy Development', para: 'We analyze business processes, identify opportunities, and create a tailored transformation strategy.' },
                    {id:2 , heading: 'Process Optimization', para: 'We streamline workflows, eliminate inefficiencies, and integrate automation to boost productivity.' },
                    {id:3 , heading: 'Technology Implementation', para: 'We deploy cloud, AI, and IoT solutions to modernize operations and enhance scalability.' },
                    {id:4 , heading: 'Management & Training', para: 'We ensure smooth adoption with structured training and a culture of innovation.' },
                    {id:5 , heading: 'Data Analytics', para: 'We use data insights to drive decisions and ensure the transformation meets business goals.' },
                    {id:6 , heading: 'Continuous Improvement', para: 'We monitor performance, refine strategies, and adapt to emerging technologies.' },
                ],
                techHeading: "Tech Solutions We Implement",
                techPara: 'Accelerating Change with Digital Transformation Innovation',
                techCard1: "Cloud Computing Platforms",
                card1Para1: 'We provide scalable, flexible cloud solutions for seamless access and collaboration.',
                techCard2: "AI & ML",
                card2Para2: 'We enable data-driven insights, predictive analytics, and personalized experiences.',
                techCard3: "Cybersecurity Solutions",
                card3Para3: 'We implement robust security measures to protect data and ensure compliance.',
                SecondHeading: "Need a smarter way to manage data and improve business visibility?",
                secondPara: "NaskSoft’s Digital Transformation services provide the technical and strategic expertise needed to optimize operations and drive growth.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    // {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },
    {
        page: "Ecommerce Solutions",
        slug: "ecommerce-solutions",
        details: [
            {
                page: "Ecommerce Solutions",
                img: ecommerce,
                title: "Smart Storefront Solutions",
                para: "We design and develop dynamic e-commerce solutions that elevate user experiences and boost sales. By combining strategic planning with cutting-edge technology, we create seamless, high-performing online stores tailored to your business objectives. ",
                point_1:'Digital Commerce',
                point_2:'Sales Solutions',
                processHeading: 'End-to-End E-Commerce Solutions',
                processCard: [
                    { id:1 , heading: 'Consultation & Strategy', para: 'We define goals, target audiences, and market trends to craft a tailored plan.' },
                    { id:2 , heading: 'Design & UX', para: 'We create intuitive, responsive designs for seamless shopping experiences.' },
                    { id:3 , heading: 'Platform Selection', para: 'We develop secure, scalable stores on Shopify, WooCommerce, or custom solutions.' },
                    { id:4 , heading: 'Advanced Technologies', para: 'We integrate AI, chatbots, and analytics for smarter shopping experiences.' },
                    { id:5 , heading: 'Testing & QA', para: 'We ensure top performance, security, and compatibility before launch.' },
                    { id:6 , heading: 'Launch & Support', para: 'We deploy, monitor, and optimize your store with ongoing updates.' },
                ],
                techHeading: "Technologies We Integrate",
                techPara: 'Enabling Efficient E-commerce with Leading-Edge Technologies',
                techCard1: "E-Commerce Platforms",
                card1Para1: 'We build on Shopify, WooCommerce, and Magento for scalable, secure stores.',
                techCard2: "Payment Gateways",
                card2Para2: 'We integrate secure, multi-method payment solutions for global transactions.',
                techCard3: "Analytics & Reporting Tools",
                card3Para3: 'Advanced analytics track sales, customer behavior, and site performance.',
                SecondHeading: "Want to optimize data flow and unlock deeper insights for your e-commerce business?",
                secondPara: "NaskSoft provides the expertise to optimize operations, enhance visibility, and drive smarter decisions.",
                projectCard:[
                    {img: img2 , name: 'Pakistan Property' , description:'PakistanProperty.com connects overseas Pakistanis with premium real estate opportunities, providing a seamless experience for buying, selling, and investing in properties across Pakistan with transparency, reliability, and expert guidance for informed decisions.' ,a:'https://www.pakistanproperty.com/'},
                    {img: img1 , name: 'WholeSale Shopping' , description:'Wholesale Shopping is a UK-based wholesale fashion distributor specializing in trendy Italian clothing, Lagenlook styles, and plus-size apparel, offering retailers high-quality fashion with nationwide delivery and a convenient click-and-collect service.' , a:"https://www.wholesaleshopping.co.uk/"},
                    // {img: img3 , name: 'Love MY Fashion' , description:'Love My Fashions is a UK-based online retailer offering affordable, trendy clothing for all sizes, promoting self-love, confidence, and inclusivity with stylish collections, quality fabrics, and fast nationwide delivery for every occasion.' , a:'https://www.lovemyfashions.com/' },
                    {img: img4, name: 'Nask International' , description:'NASK International specializes in sourcing textiles, garments, and workwear, offering comprehensive services including quality control, design, production, and timely delivery, ensuring high standards and reliability for global clients in various industries.' , a:"https://naskinternational.com/" },
                    {img: img5, name: 'Photography Studio Hub' , description:'Photography Studio Hub in Manchester provides professional photography services, studio rentals, and a vendor marketplace, seamlessly connecting clients with skilled photographers for diverse creative projects, ensuring high-quality results in a versatile studio environment.' , a:"https://photographystudiohub.com/" },
                ]
            },
        ],
    },


];

export default servicesData;
