import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { FaQuoteLeft } from 'react-icons/fa';
import img1 from '../../Images/dummy-image.jfif';
import img2 from '../../Images/images (1).jfif';
import img3 from '../../Images/unnamed (1).jpg';
import img4 from '../../Images/new.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';


const NextArrow = ({ onClick }) => {
    return (
        <button className="slick-next custom-arrow" onClick={onClick}>
            <IoIosArrowForward />
        </button>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <button className="slick-prev custom-arrow" onClick={onClick}>
            <IoIosArrowBack />
        </button>
    );
};

function Coustomer() {
  const [containerClass, setContainerClass] = useState("container-fluid");
    
        useEffect(() => {
            const updateContainerClass = () => {
                if (window.innerWidth >= 1300) {
                    setContainerClass("container p-0");
                } else {
                    setContainerClass("container-fluid p-0");
                }
            };
    
            updateContainerClass();
            window.addEventListener("resize", updateContainerClass);
        })

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };




    const testimonials = [
        {
            name: 'We Create a Better World by Digitally Innovating',
            company: '—  Michael R., CTO, InnovateTech',
            text: 'Nasksoft’s software solutions streamlined our operations, saving time and boosting efficiency. Exceptional service and seamless integration!',
            image: img3,
        },
        {
            name: 'Building High-Performing Platforms for Engagement',
            company: '— Daniel K., Founder, E-Commerce Hub',
            text: 'They have built a high-performing, user-friendly platform that improved customer engagement. Professional, skilled, and highly recommended.',
            image: img1,
        },
        {
            name: 'Empowering Businesses with Cutting-Edge AI Solutions',
            company: '— Sophia L., CEO, DataBridge Analytics',
            text: 'Nasksoft developed an AI-powered application that gave us a competitive edge. Outstanding expertise and commitment to quality!',
            image: img2,
        },
        {
            name: 'Optimized Performance Through Cloud Innovation',
            company: 'Microsoft',
            text: 'Amazing design, easy to customize and a design quality superlative account on its cloud platform for optimized performance.',
            image: img4,
        },
    ];
    
    
    
    

    return (
        <div className="coustmers">
        <div className={containerClass}>
            <h2>Words from Our Valued Clients
            </h2>
            <Slider {...settings} className='mt-5'>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className='p-3 '>
                        <div className='mian-card'  data-aos="zoom-in">
                            <div className='coustmer-card'>
                                <div className='great'>
                                    <h4>{testimonial.name}
                                    </h4>
                                </div>
                                <div className='coustmer-icon'>
                                    <FaQuoteLeft />
                                </div>
                            </div>
                            <div className='coustmer-pera'>
                                <p>"{testimonial.text}"</p>
                            </div>
                            <div className='coustmer-card1 mt-5'>
                                <div className='great'>
                                    <img src={testimonial.image} alt={testimonial.name} />
                                </div>
                                <div className='coustmer-icon'>
                                    <h3>Words from Our Valued Clients</h3>
                                    <h4>{testimonial.company}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        </div>
    );
}

export default Coustomer;